import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      guest: true,
      title: "TukTuk Zimbabwe Online Store",
      metaTags: [
        {
          name: "description",
          content:
            "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
        }
      ]
    }
  },
  {
    path: "/shoppingCart",
    name: "Cart",
    component: () => import("../views/mobileCart.vue"),
    meta: {
      guest: true,
      title: "TukTuk Zimbabwe Online Store",
      metaTags: [
        {
          name: "description",
          content:
            "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
        }
      ]
    }
  },
  {
    path: "/user",
    name: "User",
    component: () => import("../views/mobileUser.vue"),
    meta: {
      guest: true,
      title: "TukTuk Zimbabwe Online Store",
      metaTags: [
        {
          name: "description",
          content:
            "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
        }
      ]
    }
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/mobileCheckout.vue"),
    meta: {
      guest: true,
      title: "TukTuk Zimbabwe Online Store",
      metaTags: [
        {
          name: "description",
          content:
            "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
        }
      ]
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
    meta: {
      is_admin: true,
      title: "TukTuk Zimbabwe Online Store",
      metaTags: [
        {
          name: "description",
          content:
            "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
        }
      ]
    },
    children: [
      {
        path: "orders",
        name: "Orders",
        component: () => import("../views/admin/Orders.vue"),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: "TukTuk Zimbabwe Online Store",
          metaTags: [
            {
              name: "description",
              content:
                "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
            }
          ]
        }
      },
      {
        path: "products",
        name: "Products",
        component: () => import("../views/admin/Products.vue"),
        meta: {
          requiresAuth: true,
          is_admin: true,
          title: "TukTuk Zimbabwe Online Store",
          metaTags: [
            {
              name: "description",
              content:
                "TukTuk is a retailer for online grocery, fruits and vegetable delivery is Zimbabwe.Receive farm fresh food in the comfort of your home at affordable prices"
            }
          ]
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

import auth from "../store/modules/auth";
router.beforeEach((to, from, next) => {
  if (to.meta.is_admin) {
    if (auth.state.user.loggedIn) {
      var user = JSON.parse(window.localStorage.getItem("user"));
      switch (user.isAdmin) {
        case true:
          next();
          break;
        case false:
          next({ name: "Home" });
      }
    } else {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

export default router;
