<template>
  <v-app>
    <v-main class="pt-0">
      <router-view />
      <BottomNav />
    </v-main>
  </v-app>
</template>

<script>
import BottomNav from "./components/bottomNav";

export default {
  name: "App",

  components: {
    BottomNav
  },
  data: () => ({ drawer: false, basket: false }),

  created() {
    this.$store.dispatch("getCurrencies");
    this.$store.dispatch("saveCart");
  }
};
</script>
