import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import { fb } from "./firebase";

Vue.config.productionTip = false;

fb.auth().onAuthStateChanged(user => {
  var data = null;
  if (user != null) {
    data = user.uid;
  }
  store.dispatch("auth/fetchUser", data);
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
