import { fb, db, ath } from "../../firebase";

const auth = {
  namespaced: true,

  state: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : { loggedIn: false, data: { isAdmin: false } }
  },

  mutations: {
    setLoggedIn(state, value) {
      state.user.loggedIn = value;
      if (state.user.loggedIn == false) {
        var step = { value: -1 };
        this.commit("setStep", step, { root: true });
      }
    },
    setUser(state, data) {
      state.user.data = data;
      if (data.email != null) {
        state.user.loggedIn = true;
        window.localStorage.setItem("user", JSON.stringify(data));
        var step = { value: 1 };
        this.commit("setStep", step, { root: true });
      } else {
        state.step = 1;
      }
    },
    setAuth(state, data) {
      state.user.data = data;
    },
    logout(state) {
      state.user = {
        loggedIn: false,
        data: {
          userId: null,
          username: null,
          email: null,
          phoneNumber: null,
          isAdmin: false
        }
      };
      var step = { value: -1 };
      this.commit("setStep", step, { root: true });
      this.commit("reset", step, { root: true });
    },
    signUp(data) {
      console.log(data);
    }
  },

  actions: {
    fetchUser({ commit }, userId) {
      if (userId) {
        commit("setLoggedIn", true);
      } else {
        commit("setLoggedIn", false);
      }
    },
    signIn({ commit }, data) {
      commit("setLoading", true, { root: true });
      var user = {};
      fb.auth()
        .signInWithEmailAndPassword(data.email, data.pwd)
        .then(userCredential => {
          var query = db
            .collection("users")
            .where("userid", "==", userCredential.user.uid);
          query
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                user = doc.data();
              });
              commit("setUser", {
                userId: user.userid,
                username: user.username,
                email: user.email,
                phoneNumber: user.phoneNumber,
                isAdmin: user.isAdmin
              });
            })
            .catch(err => {
              this.commit("setLoading", false, { root: true });
              this.commit("setMsg", err.message, { root: true });
            });
        })
        .catch(err => {
          this.commit("setLoading", false, { root: true });
          this.commit("setMsg", err.message, { root: true });
        });
    },
    signUp({ commit }, data) {
      commit("setLoading", true, { root: true });
      var users = db.collection("users");
      fb.auth()
        .createUserWithEmailAndPassword(data.email, data.pwd)
        .then(result => {
          var username = data.username;
          var phoneNumber = data.phoneNumber;
          result.user
            .updateProfile({
              displayName: data.username,
              phoneNumber: data.phoneNumber
            })
            .then(() => {
              var user = {
                userid: result.user.uid,
                username: username,
                email: result.user.email,
                phoneNumber: phoneNumber,
                isAdmin: false
              };
              users
                .add(user)
                .then(() => {
                  commit("setUser", user);
                  this.commit("setStep", { value: 1 }, { root: true });
                  commit("setLoading", false, { root: true });
                  commit("setMsg", "Sign up was successful, you can login", {
                    root: true
                  });
                })
                .catch(err => {
                  commit("setLoading", false, { root: true });
                  commit("setMsg", err.message, { root: true });
                });
            })
            .catch(err => {
              commit("setLoading", false, { root: true });
              commit("setMsg", err.message, { root: true });
              // ...
            });
        })
        .catch(err => {
          commit("setLoading", false, { root: true });
          commit("setMsg", err.message, { root: true });
          // ...
        });
    },
    logout({ commit }) {
      fb.auth()
        .signOut()
        .then(() => {
          commit("logout");
        })
        .catch(() => {
          console.log("failed to log out ");
        });
    },
    reset({ commit }, email) {
      commit("setLoading", true, { root: true });
      ath
        .sendPasswordResetEmail(email)
        .then(() => {
          commit("setLoading", false, { root: true });
          commit(
            "setMsg",
            "KIndly check your email to complete reset process",
            { root: true }
          );
        })
        .catch(err => {
          commit("setLoading", false, { root: true });
          commit("setMsg", err.message, { root: true });
        });
    }
  },

  getters: {
    user: state => state.user
  }
};

export default auth;
