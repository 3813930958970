import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/auth");
require("firebase/storage");
require("firebase/functions");

const firebaseConfig = {
  apiKey: "AIzaSyDjf7w_lilXUuTvYhnWNsOcK1ZqvXOmLYk",
  authDomain: "greenfox-c4105.firebaseapp.com",
  databaseURL: "https://greenfox-c4105.firebaseio.com",
  projectId: "greenfox-c4105",
  storageBucket: "greenfox-c4105.appspot.com",
  messagingSenderId: "969845737566",
  appId: "1:969845737566:web:d69a62cdae5f8e39c40ccf"
};
// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);

const ath = firebase.auth();

const db = firebase.firestore();

const st = firebase.storage();

const fn = firebase.functions();

export { fb, db, st, fn, ath };
