import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#49a010",
        error: "#da7b93",
        success: "#376e6f",

        secondary: "#ddffd5",
        accent: "#d9a90f"
      },
      dark: {
        primary: "#49a010",
        error: "#da7b93",
        success: "#376e6f",

        secondary: "#ddffd5",
        accent: "#d9a90f"
      }
    }
  }
});
