import Vue from "vue";
import Vuex from "vuex";
import { db } from "../firebase";

import auth from "./modules/auth";

Vue.use(Vuex);

let cart = JSON.parse(window.localStorage.getItem("cart"))
  ? JSON.parse(window.localStorage.getItem("cart"))
  : [];
let orderId = JSON.parse(window.localStorage.getItem("orderId"))
  ? JSON.parse(window.localStorage.getItem("OrderId"))
  : 3;
let cartTotal =
  cart.length > 0
    ? cart.forEach(item => {
        cartTotal = cartTotal + item.price * item.quantity;
      })
    : 0;

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    msg: "",
    showMsg: false,
    step: 1,
    load: false,
    products: [],
    orders: [],
    currencies: [],
    currency: {},
    orderId: orderId,
    cart: cart,
    total: cartTotal,
    searchVal: null,
    filterVal: null,
    success: false
  },
  mutations: {
    reset(state){
      state.cart = [];
      state.orderId = "";
      state.total = 0;
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("orderId");
      window.localStorage.removeItem("cart");
    },
    setLoading(state, data) {
      state.load = data;
      state.showMsg = false;
    },
    setOrder(state, order) {
      state.orderId = order;
      var data = { name: "orderId", value: state.orderId };
      this.commit("save", data);
    },
    setStep(state, step) {
      if (step.value > -1) {
        this.commit("createOrder", step);
        var data = { name: "step", value: state.step };
        this.commit("save", data);
      } else {
        state.step = 1;
      }
    },
    back(state) {
      state.step--;
    },
    setMsg(state, msg) {
      state.msg = msg;
      state.showMsg = true;
    },
    setShowMsg(state) {
      state.showMsg = !state.showMsg;
    },
    setLoad(state) {
      state.load = !state.load;
    },
    search(state, data) {
      state.searchVal = data;
    },
    filter(state, data) {
      state.filterVal = data;
    },
    getProducts(state) {
      let products = [];
      db.collection("products")
        .orderBy("name")
        .onSnapshot(snapshot => {
          products = [];
          snapshot.forEach(product => {
            products.push({
              id: product.id,
              name: product.data().name,
              category: product.data().category,
              unit: product.data().unit,
              price: product.data().price,
              image: product.data().image,
              description: product.data().description
            });
          });
          state.products = products;
        });
    },
    getOrders(state, orders) {
      state.orders = orders;
    },
    getCurrencies(state) {
      db.collection("currencies")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(currency => {
            state.currencies.push({
              id: currency.id,
              denom: currency.data().denom,
              value: currency.data().value,
              status: currency.data().state
            });
            state.currency = state.currencies.find(
              currency => currency.status == true
            );
          });
        })
        .catch(() => {
          state.currency = { id: 0, denom: "USD", value: 1, status: true };
        });
    },
    addtoCart(state, item) {
      let found = state.cart.find(product => product.id == item.id);
      if (found) {
        let index = state.cart.indexOf(item);
        item.quantity = found.quantity + 1;
        state.cart.splice(index, 1, item);
      } else {
        item.quantity = 1;
        state.cart.push(item);
      }
      this.commit("saveCart");
    },
    removefromCart(state, item) {
      let found = state.cart.find(product => product.id == item.id);
      let index = state.cart.indexOf(item);
      if (found) {
        if (item.quantity > 1) {
          item.quantity = found.quantity - 1;
          state.cart.splice(index, 1, item);
        } else {
          state.cart.splice(index, 1);
        }
        this.commit("saveCart");
      }
    },
    saveCart(state) {
      state.total = 0;
      state.cart.forEach(item => {
        state.total = state.total + item.price * item.quantity;
      });
      window.localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    changeCurrency(state) {
      console.log(state.currencies);
      state.currencies.forEach(currency => {
        currency.status = !currency.status;
      });
      state.currency = state.currencies.find(
        currency => currency.status == true
      );
    },
    createOrder(state, step) {
      switch (step.value) {
        case 1:
          var counterRef = db.collection("counter").doc("nXgKlWmLW7rLrv3CE5Yb");
          var newOrder = db.collection("orders");
          counterRef
            .get()
            .then(id => {
              var orderNumber = id.data().value + 1;
              counterRef
                .update({
                  value: orderNumber
                })
                .then(() => {
                  newOrder
                    .add({
                      order: orderNumber,
                      user: state.auth.user.data.username,
                      cart: state.cart,
                      total: state.total,
                      transactionDate: new Date().toLocaleDateString(),
                      status: "Checking Out"
                    })
                    .then(res => {
                      this.dispatch("setOrder", res.id);
                      this.commit("setLoading", false);
                      this.commit("setMsg", "Authentication was successfull");
                      state.step = step.value + 1;
                    })
                    .catch(err => {
                      this.commit("setLoading", false);
                      this.commit("setMsg", err.message);
                      state.step = step.value;
                    });
                })
                .catch(err => {
                  this.commit("setLoading", false);
                  this.commit("setMsg", err.message);
                  state.step = step.value;
                });
            })
            .catch(err => {
              this.commit("setLoading", false);
              this.commit("setMsg", err.message);
              state.step = step.value;
            });
          break;
        case 2:
          db.collection("orders")
            .doc(state.orderId)
            .update({
              receipient: step.data.receipient,
              phone: step.data.phone,
              destination: step.data.destination
            })
            .then(() => {
              this.commit("setMsg", "Delivery Details Saved");
              state.step = step.value + 1;
            })
            .catch(err => {
              this.commit("setMsg", err.message);
              state.step = step.value;
            });
          this.commit("setLoad");
          break;
        case 3:
          var dlv = {
            category: "Delivery",
            description: "delivery Time",
            id: step.data.deliveryType,
            image: "",
            name: step.data.deliveryType,
            price: step.data.charge,
            quantity: 1,
            unit: "delivery"
          };
          this.commit("removefromCart", dlv);
          this.commit("addtoCart", dlv);
          db.collection("orders")
            .doc(state.orderId)
            .update({
              frequency: step.data.frequency,
              deliveryType: step.data.deliveryType,
              deliveryTime: step.data.deliveryTime,
              cart: state.cart,
              total: state.total
            })
            .then(() => {
              this.commit("setMsg", "Delivery Time Saved");
              state.step = step.value + 1;
            })
            .catch(err => {
              this.commit("setMsg", err.message);
              state.step = step.value;
            });
          this.commit("setLoad");
          break;
        case 4:
          if (step.data.confirmed) {
            var rate = state.currencies.find(
              currency => currency.denom == "$ZWL"
            ).value;
            db.collection("orders")
              .doc(state.orderId)
              .update({
                cart: state.cart,
                status: "Order Confirmed",
                total: state.total.toFixed(2),
                zwlRate: rate
              })
              .then(() => {
                state.success = true;
                state.step = state.step + 1;
                this.commit("setMsg", "Order Saved");
              })
              .catch(err => {
                this.commit("setMsg", err.message);
                state.success = false;
                state.step = step.value;
              });
          }
          this.commit("setLoad");
          break;
      }
    },
    save(state, item) {
      window.localStorage.setItem(item.name, JSON.stringify(item.value));
    }
  },
  actions: {
    setLoading({ commit }, data) {
      commit("setLoading", data);
    },
    setOrder(context, order) {
      context.commit("setOrder", order);
    },
    setStep(context, step) {
      context.commit("setStep", step);
    },
    setLoad(context) {
      context.commit("setLoad");
    },
    setMsg(context, msg) {
      context.commit("setMsg", msg);
    },
    setShowMsg(context) {
      context.commit("setShowMsg");
    },
    back(context) {
      context.commit("back");
    },
    search(context, data) {
      context.commit("search", data);
    },
    filter(context, data) {
      context.commit("filter", data);
    },
    getProducts(context) {
      context.commit("getProducts");
    },
    getOrders(context) {
      let orders = [];
      db.collection("orders").onSnapshot(snapshot => {
        snapshot.forEach(order => {
          orders.push({
            id: order.id,
            order: order.data().order,
            user: order.data().user,
            deliveryTime: order.data().deliveryTime,
            deliveryType: order.data().deliveryType,
            receipient: order.data().receipient,
            destination: order.data().destination,
            frequency: order.data().frequency,
            phone: order.data().phone,
            total: order.data().total,
            status: order.data().status,
            cart: order.data().cart,
            zwlRate: order.data().zwlRate
          });
        });
      });
      console.log(orders);
      context.commit("getOrders", orders);
    },
    getCurrencies(context) {
      context.commit("getCurrencies");
    },
    addItem(context, item) {
      context.commit("addtoCart", item);
    },
    removeItem(context, item) {
      context.commit("removefromCart", item);
    },
    chngCurrency(context) {
      context.commit("changeCurrency");
    },
    saveCart(context) {
      context.commit("saveCart");
    }
  },
  modules: { auth },
  getters: {
    user: state => state.user,
    step: state => state.step,
    load: state => state.load,
    msg: state => state.msg,
    showMsg: state => state.showMsg,
    orderId: state => state.orderId,
    products: state => state.products,
    orders: state => state.orders,
    cart: state => state.cart,
    total: state => state.total.toFixed(2),
    currencies: state => state.currencies,
    currency: state => state.currency,
    searchVal: state => state.searchVal,
    filterVal: state => state.filterVal
  }
});
