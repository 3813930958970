<template>
  <v-bottom-navigation
    class="hidden-md-and-up"
    :value="value"
    color="primary"
    grow
    bottom
    app
  >
    <v-btn @click="goto('Home')">
      <span>Home</span>

      <v-icon>mdi-home</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn @click="goto('Cart')">
      <v-badge
        color="green"
        class="mr-16"
        :content="currency.denom + cartTotal"
      >
        <v-icon>mdi-basket</v-icon>
      </v-badge>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({ value: 1 }),

  computed: {
    cartTotal() {
      let total = this.currency.value * this.$store.getters.total;
      return total;
    },
    currency() {
      return this.$store.getters.currency;
    }
  },
  methods: {
    goto(path) {
      this.$router.push({ name: path });
    }
  }
};
</script>
